import React from 'react'
import Profile from '../../assets/dipesh.jpg'
import { Link } from 'react-router-dom'
import { FaArrowRight } from 'react-icons/fa'
import "./home.css"

const Home = () => {
  return (
    <section className="home section grid">
      <img src={Profile} alt="" className="home__img" />

      <div className="home__content">
        <div className="home__data">
          <h1 className="home__title">
            <span>I'm Dipesh Bartaula.</span> Software Engineer
          </h1>

          <p className="home__description">
            Based in Kathmandu, Nepal, I am a dedicated Computer Engineering student with a fervor for staying ahead of the curve in technology. Specializing in Full Stack Development, I excel in crafting software solutions that seamlessly integrate frontend and backend architectures.
          </p>

          <Link to='/about' className='button'>
            More About Me {' '}
            <span className='button__icon'>
              <FaArrowRight />
            </span>
          </Link>
        </div>
      </div>

      <div className="color__block"></div>
    </section>
  )
}

export default Home